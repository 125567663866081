import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

const DateSelection = ({
  getToday,
  getCurrentWeek,
  firstDate,
  lastDate,
  getSelectedMonth,
  todayBtnClicked,
  weekClicked,
  currentMonth,
  downloadGMV,
  GMV,
}) => {
  const [months, setmonths] = useState([]);
  useEffect(() => {
    let theMonths = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let now = new Date();
    let newData = [];
    for (let i = 0; i < 60; i++) {
      let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
      let month = theMonths[future.getMonth()];
      let year = future.getFullYear();
      newData.push(month + " " + year);
    }
    setmonths(...months, newData);
  }, []);
  return (
    <div className="dateSelection">
      <div style={{ display: "flex" }}>
        <button
          className="dateBtn"
          onClick={getToday}
          style={{
            backgroundColor: todayBtnClicked ? "#ff9797" : "",
            color: todayBtnClicked ? "#ffffff" : "#000",
          }}
        >
          Today
        </button>

        <button
          className="dateBtn"
          onClick={getCurrentWeek}
          style={{
            backgroundColor: weekClicked ? "#ff9797" : "",
            color: weekClicked ? "#ffffff" : "#000",
          }}
        >
          Current Week
        </button>
        <div className="customdropdown">
          <select
            className={currentMonth != 0 ? "selectedMonth" : ""}
            onChange={(event) => {
              getSelectedMonth(event.target.value);
            }}
            value={currentMonth}
            style={{ backgroundImage: "url(/images/dropdown.svg)" }}
          >
            <option hidden>Month</option>
            {months?.map((month, index) => (
              <option style={{ width: "30%" }} key={index} value={month}>
                {month}
              </option>
            ))}
          </select>
        </div>
        <div className="datePickerDesktop">
          <img
            src="/images/calendar.svg"
            style={{ width: "21px", height: "20px", marginRight: "10px" }}
            alt="date calendar"
          />
          <span>
            {dayjs(firstDate).format("ddd,D MMM")} to{" "}
            {dayjs(lastDate).format("ddd,D MMM")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default DateSelection;
